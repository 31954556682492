<template>
  <div>
    <div :id="id" class="echarts"></div>
  </div>
</template>
<script>
import echartsMixins from '@/assets/js/echartsMixins.js'

import chinaJson from '@/assets/china.json'
export default {
  name: '',
  components: {},
  mixins: [echartsMixins],
  props: ['id', 'ehartData'],
  data() {
    return {
      data1: [],
      data2: [],
      visColor: [
        '#ffffff',
        '#e8192f',
        '#d6664d',
        '#B9044E',
        '#f0ba2e',
        '#d3ce2b',
        '#169A7f',
        '#0b6573',
        '#1BB3c8',
      ],
      myChart: '',
    }
  },
  computed: {},
  watch: {
    ehartData: {
      handler(newVal, oldVal) {
        this.formatData()
        this.initEcharts()
      },
      deep: true,
    },
  },
  mounted() {
    this.formatData()
    this.myChart = this.$echarts.init(document.getElementById(this.id))
    this.$echarts.registerMap(this.id, chinaJson)

    this.initEcharts()
  },
  methods: {
    // 刷新组件
    handlerResize() {
      this.myChart.resize()
    },
    formatData() {
      let data1 = [],
        data2 = []
      this.ehartData.forEach((e) => {
        data1.push({
          name: e.province_name,
          value: e.ins_cnt ? e.ins_cnt : 0,
        })
        data2.push({
          name: e.province_name,
          value: [
            {
              name: '医院数量',
              value: e.hospital_cnt ? e.hospital_cnt  : 0 ,
            },
            {
              name: '仪器数量',
              value: e.ins_cnt ? e.ins_cnt  : 0 ,
            },
            {
              name: '本月用量',
              value: e.dosage_sum
                ? this.$tool.moneyFormatter(e.dosage_sum, 0)
                : 0 ,
            },
          ],
        })
      })
      this.data1 = data1
      this.data2 = data2
    },
    initEcharts() {
      var geoCoordMap = {}

      var toolTipData = this.data2
      this.myChart.showLoading()
      var mapFeatures = this.$echarts.getMap(this.id).geoJson.features
      this.myChart.hideLoading()
      mapFeatures.forEach(function (v) {
        // 地区名称
        var name = v.properties.name
        // 地区经纬度
        geoCoordMap[name] = v.properties.cp
      })
      var max = 480,
        min = 9 // todo
      var maxSize4Pin = 100,
        minSize4Pin = 20

      var convertData = function (data) {
        var res = []
        for (var i = 0; i < data.length; i++) {
          var geoCoord = geoCoordMap[data[i].name]
          if (geoCoord) {
            res.push({
              name: data[i].name,
              value: geoCoord.concat(data[i].value),
            })
          }
        }
        return res
      }
      var option = {
        title: {
          text: '全国仪器心跳分布图',
          x: 'center',
          top: 100,
          textStyle: {
            color: 'rgb(55, 75, 113)',
            fontFamily: '黑体',
            fontSize: 24,
          },
          subtextStyle: {
            fontSize: 15,
            fontFamily: '等线',
          },
        },
        tooltip: {
          trigger: 'item',
          formatter: function (params) {
            if (typeof params.value[2] == 'undefined') {
              var toolTiphtml = ''
              for (var i = 0; i < toolTipData.length; i++) {
                if (params.name == toolTipData[i].name) {
                  toolTiphtml += `<div style="width:200px; border-radius: 5px;position: absolute;left:-2px;top:-2px;background:#fff;">
                  <P style="100%;background-color:#43AAD7;text-align: center;font-size: 18px;padding:5px;color:#fff">${toolTipData[i].name}</p>`
                  for (var j = 0; j < toolTipData[i].value.length; j++) {
                    toolTiphtml += `<div style="display:flex;padding:5px;">
                    <p style="flex:1">
                      ${toolTipData[i].value[j].name}
                    </p>
                    <p style="flex:2;text-align:right;">
                    
                      <span style="color:#166DC9;font-size:20px">${toolTipData[i].value[j].value}</span>${j==0?'家':j==1?'台':'人份'}
                    </p>
                  </div>  `
                  }
                  toolTiphtml += `</div>`

                  // toolTiphtml += toolTipData[i].name + ':<br>'
                  // for (var j = 0; j < toolTipData[i].value.length; j++) {
                  //   // toolTiphtml +=
                  //   //   toolTipData[i].value[j].name +
                  //   //   ':' +
                  //   //   toolTipData[i].value[j].value +
                  //   //   '<br>'
                  //   toolTiphtml +=
                  //     `${toolTipData[i].value[j].name}:<span style="fontSize:40px">${toolTipData[i].value[j].value}</span><br>`
                  // }
                }
              }
              return toolTiphtml
            } else {
              var toolTiphtml = ''
              for (var i = 0; i < toolTipData.length; i++) {
                if (params.name == toolTipData[i].name) {
                  toolTiphtml += `<div style="width:200px; border-radius: 5px;position: absolute;left:-2px;top:-2px;background:#fff;">
                  <P style="100%;background-color:#43AAD7;text-align: center;font-size: 18px;padding:5px;color:#fff">${toolTipData[i].name}</p>`
                  for (var j = 0; j < toolTipData[i].value.length; j++) {
                    toolTiphtml += `<div style="display:flex;padding:5px;">
                    <p style="flex:1">
                      ${toolTipData[i].value[j].name}
                    </p>
                    <p style="flex:2;text-align:right;">
                    
                      <span style="color:#166DC9;font-size:20px">${toolTipData[i].value[j].value}</span>${j==0?'家':j==1?'台':'人份'}
                    </p>
                  </div>  `
                  }
                  toolTiphtml += `</div>`
                  // toolTiphtml += toolTipData[i].name + ':<br>'
                  // for (var j = 0; j < toolTipData[i].value.length; j++) {
                  //   // toolTiphtml +=
                  //   //   toolTipData[i].value[j].name +
                  //   //   ':' +

                  //   //   toolTipData[i].value[j].value +
                  //   //   '<br>'
                  //   toolTiphtml += `${toolTipData[i].value[j].name}:<p style:'color:red;'>${toolTipData[i].value[j].value}</p>`
                  // }
                }
              }
              // console.log(convertData(data))
              return toolTiphtml
            }
          },
        },
        // legend: {
        //     orient: 'vertical',
        //     y: 'bottom',
        //     x: 'right',
        //     data: ['credit_pm2.5'],
        //     textStyle: {
        //         color: '#fff'
        //     }
        // },
        visualMap: {
          show: true,
          min: 0,
          max: 300,
          left: 40,
          bottom: 100,
          text: ['高', '低'], // 文本，默认为数值文本
          calculable: true,
          seriesIndex: [1],
          inRange: {
         
            color: ['#ccc', '#5DBD6A'], //灰绿
          },
        },
        /*工具按钮组*/
        // toolbox: {
        //     show: true,
        //     orient: 'vertical',
        //     left: 'right',
        //     top: 'center',
        //     feature: {
        //         dataView: {
        //             readOnly: false
        //         },
        //         restore: {},
        //         saveAsImage: {}
        //     }
        // },
        geo: {
          show: true,
          map: this.id,
          label: {
            normal: {
              show: false,
            },
            emphasis: {
              show: false,
            },
          },
          roam: false,
          select: {
            display: false,
          },
          itemStyle: {
            normal: {
              areaColor: '#031525',
              borderColor: '#3B5077',
            },
            emphasis: {
              // areaColor: '#2B91B7',
              areaColor: '#FFDF33',

            },
          },
        },
        series: [
          {
            name: '散点',
            type: 'scatter',
            coordinateSystem: 'geo',
            data: convertData(this.data1),
            symbolSize: function (val) {
              return val[2] / 10
            },
            label: {
              normal: {
                formatter: '{b}',
                position: 'right',
                show: true,
              },
              emphasis: {
                show: false,
              },
            },
            itemStyle: {
              normal: {
                color: '#05C3F9',
              },
            },
          },
          {
            type: 'map',
            map: this.id,
            geoIndex: 0,
            aspectScale: 0.75, //长宽比
            showLegendSymbol: false, // 存在legend时显示
            label: {
              normal: {
                show: true,
              },
              emphasis: {
                show: false,
                textStyle: {
                  color: '#fff',
                },
              },
            },
            roam: true,
            itemStyle: {
              normal: {
                areaColor: '#031525',
                borderColor: '#3B5077',
              },
              emphasis: {
                areaColor: '#2B91B7',
              },
            },
            animation: false,
            data: this.data1,
          },
          {
            name: '点',
            type: 'scatter',
            coordinateSystem: 'geo',
            symbol: 'pin', //气泡
            symbolSize: function (val) {
              var a = (maxSize4Pin - minSize4Pin) / (max - min)
              var b = minSize4Pin - a * min
              b = maxSize4Pin - a * max
              return val[2]?a * val[2] + b : 0
            },
            label: {
              normal: {
                show: true,
                textStyle: {
                  color: '#fff',
                  fontSize: 9,
                },
                formatter: (res) => {
                  return res.value[2]?res.value[2] : " "
                },
              },
            },
            itemStyle: {
              normal: {
                color: '#F62157', //标志颜色
              },
            },
            zlevel: 6,
            data: convertData(this.data1),
          },
          {
            type: 'effectScatter',
            coordinateSystem: 'geo',
            data: convertData(this.data1),
            symbolSize: function(val) {
                // return val[2] / 10;
                return val[2]?15:0
            },
            showEffectOn: 'render',
            zlevel: 2,
            rippleEffect: {
                period: 2.5, //波纹秒数
                brushType: 'fill', //stroke(涟漪)和fill(扩散)，两种效果
                scale: 2, //波纹范围
            },
            hoverAnimation: true,
            label: {
                normal: {
                    formatter: '{b}',
                    position: 'top',
                     show: false,
                }
            },
            itemStyle: {
                normal: {
                    show: false,
                    color: "#0579FA", //字体和点颜色
                    label: {
                        textStyle: {
                            fontWeight: 'bold', //字体
                            fontSize: 18, //字体大小
                            color: "#023AFD"
                        }
                    },
                }
            },
        }
          // {
          //     name: 'Top 5',
          //     type: 'effectScatter',
          //     coordinateSystem: 'geo',
          //     data: convertData(data.sort(function(a, b) {
          //         return b.value - a.value;
          //     }).slice(0, 5)),
          //     symbolSize: function(val) {
          //         return val[2] / 10;
          //     },
          //     showEffectOn: 'render',
          //     rippleEffect: {
          //         brushType: 'stroke'
          //     },
          //     hoverAnimation: true,
          //     label: {
          //         normal: {
          //             formatter: '{b}',
          //             position: 'right',
          //             show: true
          //         }
          //     },
          //     itemStyle: {
          //         normal: {
          //             color: 'yellow',
          //             shadowBlur: 10,
          //             shadowColor: 'yellow'
          //         }
          //     },
          //     zlevel: 1
          // },
        ],
      }
      this.myChart.setOption(option)
    },
  },
}
</script>
<style lang='scss' scoped>
.echarts {
  width: 100%;
  height: 100%;
}
</style>