<template>
  <div class="shouyeLineEchart">
    <div class="dw">单位：台</div>
    <div :id="id" class="echarts"></div>
  </div>
</template>
<script>
import echartsMixins from "@/assets/js/echartsMixins.js"

export default {
  props: ['id', 'ehartData', 'ehartData2'],
  mixins: [echartsMixins],

  data() {
    return {
      myChart: '',
    }
  },
  watch: {
    ehartData: {
      handler(newVal, oldVal) {
        this.initEcharts()
      },
      deep: true,
    },
  },
  mounted() {
    this.myChart = this.$echarts.init(document.getElementById(this.id))
    this.initEcharts()
  },
  methods: {
    // 刷新组件
    handlerResize(){
      this.myChart.resize()
    },
    initEcharts() {
      var option = {
        title: {
          top: '4%',
          left: '1%',
          bottom: '15%',
          text: '各省仪器分布图',
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'line',
          },
        },
        grid: {
          top: '16%',
          left: '2%',
          right: '2%',
          bottom: '5%',
          containLabel: true,
        },
        xAxis: [
          {
            type: 'category',
            data: this.ehartData.x,
            // 刻度线
            axisTick: {
              show: false,
            },
            // axisLabel: {
            //   interval: 0,
            //   rotate: 30
            // },
            //  轴线
            axisLine: {
              lineStyle: {
                color: '#ADB3BE',
              },
            },
            //  字体颜色和大小
            axisLabel: {
              interval: 0,
              rotate: 60,
              textStyle: {
                color: '#9AA1A9',
                fontSize: '14',
              },
            },
          },
        ],
        yAxis: [
          {
            type: 'value',
            minInterval: 1,
            //  轴线
            axisLine: { show: false },
            // 刻度线
            axisTick: {
              show: false,
            },
            // 网格线
            splitLine: {
              show: true,
              lineStyle: {
                //  轴线颜色
                width: 0.5,
                color: ['#999999'],
                type: 'dashed',
              },
            },
            // 字体颜色和大小
            axisLabel: {
              textStyle: {
                color: '#9AA1A9',
                fontSize: '14',
              },
              formatter: '{value}',
            },
          },
        ],
        series: [
          {
            type: 'bar',
            barWidth: '30%',
            label: {
              show: true,
              precision: 1,
              fontWeight: 'bold',
              position: 'top',
              valueAnimation: true,
              fontFamily: 'monospace'
            },
            color: '#166DC9',
            data: this.ehartData.y,
          },
         
        ],
      }
      this.myChart.setOption(option)
    },
  },
}
</script>
<style lang="scss" scoped>
.shouyeLineEchart {
  width: 100%;
  position: relative;
  .dw {
    font-size: 14px;
    color: #999999;
    position: absolute;
    right: 20px;
    top: 23px;
  }
  .echarts {
    width: 100%;
    height: px(420);
  }
}
</style>
