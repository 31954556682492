<template>
  <div class="shouyeProgressList">
    <div class="title">
      <span>仪器用量TOP10({{topUsedMonth}})</span>
      <p class="more" @click="goDevice" v-show="btnShow">
        More
        <i class="el-icon-arrow-right"></i>
      </p>
    </div>
    <ul>
      <li v-for="(item, index) of progressData" :key="index">
        <div class="left">
          <span>{{ index >= 9 ? index + 1 : '0' + (index + 1) }}</span>
          <div>
            <p>{{ item.a }}</p>
            <p>{{ item.b }}</p>
          </div>
        </div>
        <el-progress
          :percentage="item.c"
          :format="format(item.d)"
          :stroke-width="10"
          :color="item.color ? item.color : '#93ABCC'"
        ></el-progress>
      </li>
    </ul>
  </div>
</template>
<script>
import {getinstrumentsStatistic} from '@/router/menuMain.js'
export default {
  props: ['progressData','topUsedMonth'],
  data() {
    return {
      btnShow: false,
    }
  },
  watch: {
    progressData() {},
  },
  mounted() {
    this.btnShow = getinstrumentsStatistic().includes('Dosage')
  },
  methods: {
    format(value) {
      return () => {
        return `${this.$tool.moneyFormatter(Number(value), 0)}人份`
      }
    },
    goDevice() {
      this.$router.push({
        path: 'Device',
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.shouyeProgressList {
  padding: px(20);
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: px(24);
    font-weight: bold;
    color: #203152;
    margin-bottom: px(9);
    .more {
      font-size: px(14);
      font-weight: 400;
      color: #999999;
      cursor: pointer;
      .el-icon-arrow-right {
        border: 1px solid #999999;
        border-radius: 50%;
        margin-left: px(7);
        width: px(15);
        height: px(15);
        line-height: px(16);
        text-align: center;
        font-size: px(11);
      }
    }
  }

  ul {
    overflow-y: auto;
    height: px(380);
    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: px(74);
      border-bottom: px(1) dashed #adb3be;
      box-sizing: border-box;
      .left {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        span {
          font-size: px(20);
          font-weight: bold;
          color: #203152;
          margin-right: px(7);
        }
        div {
          font-size: px(16);
          color: #506a8c;
        }
      }
      ::v-deep .el-progress {
        .el-progress-bar {
          width: px(250);
        }
        .el-progress-bar__outer {
          border-radius: 0;
        }
        .el-progress-bar__inner {
          border-radius: 0;
        }
        .el-progress__text {
          margin-left: 3.604167vw;
          font-size: px(16) !important;
          color: #333333;
        }
      }
    }
    li:nth-last-child(1) {
      border-bottom: none;
    }
  }
}
</style>
