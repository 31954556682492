<template>
  <div class="shouyePie2Echart">
    <div class="dw">单位：台</div>
    <div :id="id" class="echarts"></div>
    <ul class="UlB">
      <li v-for="(item, index) of ehartData.inner" :key="index">
        <span class="left">
          <i :style="'background:' + item.itemStyle.color"></i>
          {{ item.name }}:
        </span>
        <span class="right">{{ $tool.moneyFormatter(item.value,0) }}</span>
      </li>
    </ul>
    <p class="line"></p>
    <ul class="UlB">
      <li v-for="(item, index) of ehartData.out" :key="index">
        <span class="left">
          <i :style="'background:' + item.itemStyle.color"></i>
          <span class="name">{{ item.name }}</span>:
        </span>
        <span class="right">{{ $tool.moneyFormatter(item.value,0) }}</span>
      </li>
    </ul>
  </div>
</template>
<script>
import echartsMixins from "@/assets/js/echartsMixins.js"

export default {
  props: ['id', 'ehartData'],
  mixins: [echartsMixins],

  data() {
    return {
      myChart: {},
    }
  },
  watch: {
    ehartData: {
      handler(newVal, oldVal) {
        this.initEcharts()
      },
      deep: true,
    },
  },
  mounted() {
    this.myChart = this.$echarts.init(document.getElementById(this.id))
    this.initEcharts()
  },
  methods: {
    // 刷新组件
    handlerResize(){
      this.myChart.resize()
    },
    initEcharts() {
      // 
      var option = {
        title: {
          text: '仪器总数'+ this.$tool.moneyFormatter(Number(this.ehartData.instrument_sum), 0) ,
          top: '5%',
          left: '2%',
        },
        tooltip: {
          trigger: 'item',
          formatter: '{b} : {c} ({d}%)', // <br/>换行
        },

        series: [
          {
            name: '里圈饼状图',
            type: 'pie',
            selectedMode: 'single',
            radius: ['0', '30%'],
            center: ['50%', '50%'],
            label: {
              position: 'inner',
              formatter: (temp)=>{
                if(temp.percent>0){
                  return temp.percent+'%'
                }
                return ''
              },
              textStyle: {
                fontWeight: 'bold',
                color: '#333',
                fontSize: 8,
              },
            },
            labelLine: { show: false },

            data: this.ehartData.inner,
          },
          {
            name: '外圈饼状图',
            type: 'pie',
            selectedMode: 'single',
            radius: ['50%', '65%'],
            center: ['50%', '50%'],
            itemStyle: {
              normal: {
                label: { show: false },
              },
            },
            data: this.ehartData.out,
          },
        ],
      }
      this.myChart.setOption(option)
    },
  },
}
</script>
<style lang="scss" scoped>
.shouyePie2Echart {
  width: 100%;
  position: relative;
  .dw {
    font-size: px(16);
    color: #999999;
    position: absolute;
    right: px(20);
    top: px(23);
  }
  .echarts {
    width: 100%;
    height: px(310);
  }

  ul {
    margin: 0 px(10);
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: px(150);
      font-size: px(15);
      .left {
        color: #506a8c;
        display: flex;
        align-items: center;
        i {
          display: inline-block;
          width: px(8);
          height: px(8);
          border-radius: 50%;
          margin-right: px(5);
        }
        .name{
          white-space: nowrap;
        }
      }
      .right {
        font-weight: bold;
        color: #333333;
      }
    }
  }
  .UL1 {
    border-bottom: px(1) solid #ebf0f6;
  }
  .line {
    height: 2px;
    width: 90%;
    margin: 0 auto;
    border-bottom: px(1) solid #ebf0f6;
  }
  .UlB {
    li {
      height: px(32);
      width: px(225);
    }
  }
}
</style>
