<template>
  <div class="header0">
    <div class="header_left"></div>
    <div class="header_right">
      <div class="avatar">
        <el-avatar
          src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"
          class="el-avatar"
        />
      </div>
      <div class="user" > 
        <el-dropdown trigger="click">
          <span class="el-dropdown-link">
            {{user_name}}
            <i class="el-icon-arrow-down el-icon--right" />
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="exit">退出</el-dropdown-item>
            <el-dropdown-item @click.native="goChangePw">修改密码</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div class="line" />
      <div class="notice" @click="go_messages">
        <el-badge :value="$store.state.message_count" class="item" :hidden="$store.state.message_count<=0">
          <i class="el-icon-bell" />
        </el-badge>
      </div>
    </div>
  </div>
</template>
<script>
import { removeToken } from '@/utils/auth'
export default {
  data() {
    return {
      user_name:''
    }
  },
  created() {
    // this.user_name = this.$store.state.userInfo.user_name
    this.user_name = JSON.parse(localStorage.getItem('userInfo')).user_name
  },
  methods: {
    exit() {
      removeToken()
      window.localStorage.clear()
      window.sessionStorage.clear()
      this.$router.push('/login')
    },
    goChangePw() {
      this.$router.push({ path: '/changePassword'})
    },
    go_messages() {
      this.$router.push({ path: '/messages'})
    },
  },
}
</script>
<style lang="scss" scoped>
.header0 {
  display: flex;
  flex: 1;
  margin-bottom: px(10);
  height: px(60); // 头部组件高度
  .line {
    width: 1px;
    height: px(18);
    background-color: white;
    margin-left: px(15);
  }
  .el-icon--right {
    margin-left: px(10);
    color: #ffff;
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px px(40) 0px px(60);
  height: px(60);
  .header_left {
  }
  .header_right {
    display: flex;
    align-items: center;
    .search {
      margin-right: px(40);
      .el-icon-search {
        font-size: px(18);
        color: #1a1a1b;
      }
    }
    .notice {
      margin-left: px(15);
      .el-icon-bell {
        font-size: px(18);
        color: #ffff;
      }
    }
    .avatar {
      margin-right: px(12);
      .el-avatar {
        height: px(30);
        width: px(30);
      }
    }
    .user {
      cursor: pointer;
      .el-dropdown-link {
        color: #ffff;
        font-size: px(16);
        outline: none;
      }
    }
  }
}
</style>
