<template>
  <div class="shouyeLineEchart">
    <div class="sum">{{$tool.moneyFormatter(sum, 0)}}</div>
    <div class="dw">人份</div>
    <div :id="id" class="echarts"></div>
  </div>
</template>
<script>
import echartsMixins from "@/assets/js/echartsMixins.js"

export default {
  props: ['id', 'sum', 'ehartData','colorList'],
  mixins: [echartsMixins],

  data() {
    return {
      myChart: '',
    }
  },
  watch: {
    ehartData: {
      handler(newVal, oldVal) {
        this.initEcharts()
      },
      deep: true,
    },
  },
  mounted() {
    this.myChart = this.$echarts.init(document.getElementById(this.id))
    this.initEcharts()
  },
  methods: {
    // 刷新组件
    handlerResize(){
      this.myChart.resize()
    },
    initEcharts() {
      let that = this
      var option = {
        title: {
          text: '试剂总用量',
          top: '4%',
          left: '1%',
        },
        textStyle: {
          color: '#00AFE9',
        },
        tooltip: {
          trigger: 'item',
          // formatter: '{b} : {c} ({d}%)', // <br/>换行
          formatter: function(data){
              return `${data.data.name} : ${data.data.value} (${(data.data.value/Number(that.sum)*100).toFixed(2)}%)`
          },
        },

        series: [
          {
            type: 'pie',
            radius: [40, 110],
            center: ['50%', '60%'],
            // roseType: 'angle',
            itemStyle: {
              borderColor: '#fff',
              borderWidth: '2',
              color: (params) => {
                return this.colorList[params.dataIndex]
              },
            },
            label: {
              show: true,
              position: 'inside',
              // formatter: '{d}%',
              formatter: function(data){
                return (data.data.value/Number(that.sum)*100).toFixed(2)+'%'
              },
              textStyle: {
                fontWeight: 'bold',
                color: '#FAFAFA',
                fontSize: 16,
              },
             
            },
            data:this.ehartData,
          },
        ],
      }
      this.myChart.setOption(option)
    },
  },
}
</script>
<style lang="scss" scoped>
.shouyeLineEchart {
  width: 98%;
  position: relative;
  .sum{
    font-size: px(23);
    color: #999999;

    position: absolute;
    right: px(0);
    top: px(15);
  }
  .dw {
    font-size: px(14);
    color: #999999;
    position: absolute;
    right: px(0);
    top: px(33);
  }
  .echarts {
    width: 100%;
    height: px(405);
  }
}
</style>
