import request from '@/utils/request'
const api = {
    // 腾云首页
    index(params) {
        return request({
            url: '/api/index/',
            method: 'get',
            params
        })
    },
    // 腾云首页
    map_stats(params) {
        return request({
            url: '/api/map_stats/',
            method: 'get',
            params
        })
    },


}
export default api