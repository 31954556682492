<template>
  <div class="shouyeCategory">
    <p class="more" @click="goDosage" v-show="btnShow">
      More
      <i class="el-icon-arrow-right"></i>
    </p>
    <div :id="id" class="echarts"></div>
  </div>
</template>
<script>
import echartsMixins from "@/assets/js/echartsMixins.js"
import {getinstrumentsStatistic} from '@/router/menuMain.js'
export default {
  props: ['id', 'ehartData'],
  mixins: [echartsMixins],

  data() {
    return {
      myChart: '',
      btnShow: false,
    }
  },
  watch: {
    ehartData: {
      handler(newVal, oldVal) {
        this.initEcharts()
      },
      deep: true,
    },
  },
  mounted() {
    this.btnShow = getinstrumentsStatistic().includes('Dosage')
    this.myChart = this.$echarts.init(document.getElementById(this.id))
    this.initEcharts()
  },
  methods: {
    // 刷新组件
    handlerResize(){
      this.myChart.resize()
    },
    goDosage() {
      this.$router.push({
        path: 'Dosage',
      })
    },
    initEcharts() {
      let colorListBar = [
        '#2C52F1',
        '#F77490',
        '#00AFE9',
        '#1ACB64',
        '#F9C942',
        '#2C52F1',
      ],
      colorindex = this.ehartData.y.length
      

      var option = {
        color: ['#2D99FF',],
        title: {
          left: '1%',
          top: '5%',
          text: '近30天各省使用量',
        },

        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'line',
          },
        },
        grid: {
          left: '2%',
          right: '10%',
          bottom: '2%',
          containLabel: true,
        },
        yAxis: [
          {
            type: 'category',
            data: this.ehartData.x,
            axisTick: { show: false },
            // 控制网格线是否显示
            splitLine: { show: false },
            //  改变x轴颜色
            axisLine: {
              lineStyle: {
                width: 2,
                color: '#D7D7D7',
              },
            },
            //  改变x轴字体颜色和大小
            axisLabel: {
              textStyle: {
                color: '#9AA1A9',
                fontSize: '14',
              },
            },
          },
        ],
        xAxis: [
          {
            type: 'value',
            //  隐藏y轴
            axisLine: { show: false },
            // 去除y轴上的刻度线
            axisTick: { show: false },
            // 控制网格线是否显示
            splitLine: {
              show: true,
              //  改变轴线颜色
              onZero: false,
              lineStyle: {
                // 使用深浅的间隔色
                width: 0.5,
                color: ['#999999'],
                type: 'dashed',
              },
            },
            //  改变y轴字体颜色和大小
            axisLabel: {
              textStyle: {
                color: '#9AA1A9',
                fontSize: '14',
              },
              formatter: '{value}',
            },
          },
        ],
        series: [
          {
            type: 'bar',
            barWidth: '30%',
            itemStyle: {
              // color: '#2D99FF',
              color: function (param) {
                // colorindex--
                let index = colorindex-param.dataIndex
                return index<4? colorListBar[index] : '#5470c6';
              },
              barBorderRadius: [0, 10, 10, 0],
            },
            label: {
              formatter: (val)=>{return this.$tool.moneyFormatter(val.data,0)},
              show: true,
              precision: 1,
              position: 'right',
              valueAnimation: true,
              fontFamily: 'monospace'
            },
            data: this.ehartData.y,
          },
        ],
      }
      this.myChart.setOption(option)
    },
  },
}
</script>
<style lang="scss" scoped>
.shouyeCategory {
  width: 100%;
  height: px(437);
  overflow-y: auto;
  position: relative;
  .more {
    font-size: px(14);
    font-weight: 400;
    color: #999999;
    position: absolute;
    top: px(24);
    right: px(19);
    cursor: pointer;
    z-index: 1;
    .el-icon-arrow-right {
      border: px(1) solid #999999;
      border-radius: 50%;
      margin-left: px(7);
      width: px(15);
      height: px(15);
      line-height: px(16);
      text-align: center;
      font-size: px(11);
    }
  }
  .echarts {
    width: 100%;
    // height: px(437);
    height: px(837);
  }
}
</style>
