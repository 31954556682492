<template>
  <div class="shouyeLineEchart">
    <div class="dw">
      <span class="iconBox"><i></i></span>
      试剂用量(人份数)
    </div>
    <div :id="id" class="echarts"></div>
  </div>
</template>
<script>
import echartsMixins from "@/assets/js/echartsMixins.js"

export default {
  props: ['id', 'ehartData'],
  mixins: [echartsMixins],

  data() {
    return {
      myChart: '',
    }
  },
  watch: {
    ehartData: {
      handler(newVal, oldVal) {
        this.initEcharts()
      },
      deep: true,
    },
  },
  mounted() {
    this.myChart = this.$echarts.init(document.getElementById(this.id))
    this.initEcharts()
  },
  methods: {
    // 刷新组件
    handlerResize(){
      this.myChart.resize()
    },
    initEcharts() {
      var option = {
        color: ['#166DC9'],
        title: {
          left: '1%',
          top: '5%',
          text: '近30天的试剂消耗量',
        },
        grid: {
          top: '20%',
          left: '2%',
          right: '4%',
          bottom: '2%',
          containLabel: true,
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            lineStyle: {
              type: 'dashed',
              color: '#166DC9',
            },
          },
        },
        xAxis: [
          {
            type: 'category',
            data: this.ehartData.x,
            axisTick: {
              show: false,
            },
            // 控制网格线是否显示
            splitLine: {
              show: false,
            },
            // 轴颜色
            axisLine: {
              lineStyle: {
                color: '#ADB3BE',
              },
            },
            // 轴字体颜色和大小
            axisLabel: {
              textStyle: {
                color: '#9AA1A9',
                fontSize: '14',
              },
            },
          },
        ],
        yAxis: [
          {
            type: 'value',
            minInterval: 1,
            //  隐藏y轴
            axisLine: { show: false },
            // 去除y轴上的刻度线
            axisTick: {
              show: false,
            },
            // 控制网格线
            splitLine: {
              show: true,
              lineStyle: {
                // 使用深浅的间隔色
                width: 0.5,
                color: ['#999999'],
                type: 'dashed',
              },
            },
            // 轴字体颜色和大小
            axisLabel: {
              textStyle: {
                color: '#9AA1A9',
                fontSize: '14',
              },
              formatter: '{value}',
            },
          },
        ],
        series: [
          {
            name: '试剂用量(人份数)',
            type: 'line',
            data: this.ehartData.y,
            areaStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: '#c1d8f0', // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: '#ffffff', // 100% 处的颜色
                  },
                ],
              },
            },
          },
        ],
      }
      this.myChart.setOption(option)
    },
  },
}
</script>
<style lang="scss" scoped>
.shouyeLineEchart {
  width: 100%;
  position: relative;
  .dw {
    font-size: px(18);
    color: #999999;
    position: absolute;
    right: px(20);
    top: px(23);
    display: flex;
    justify-content: center;
    align-items: center;
    .iconBox {
      display: block;
      width: px(19);
      height: px(4);
      background: #166dc9;
      margin-right: px(6);
      position: relative;
      i {
        display: block;
        height: px(11);
        width: px(11);
        background: #166dc9;
        border-radius: 50%;
        position: absolute;
        left: px(4);
        top: px(-3);
      }
    }
  }
  .echarts {
    width: 100%;
    height: px(430);
  }
}
</style>
